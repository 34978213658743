<template>
    <div>
        <NavBar :isHome='false' />
        <div class="container-fluid contact-banner">
            <div class="row">
                <p class="contact-banner-title">Contact us</p>
                <p class="contact-banner-desc">株式会社日新サービスへのご質問・ご相談は、お電話またはメールよりどうぞお気軽にお寄せ下さい。</p>
            </div>
        </div>
        <div class="contact-body-container">
            <!-- <div class="map-container" id="map">

            </div> -->
            <div class="map-container">
                <iframe :src="src" class="map-container" frameborder="0" style="border:0;" allowfullscreen=""
                    aria-hidden="false" tabindex="0"></iframe>
            </div>

            <div style="margin-left:calc(100vw/1920*50);padding-top:15px;">
                <div>
                    <p class="contact-title">本店：</p>
                    <p class="contact-desc" style="width:unset;">〒101-0025 東京都千代田区神田佐久間町2-18-1<br>秋葉原STNフロント 12F</p>
                </div>
                <div style="margin-top:calc(100vw/1920*50)">
                    <p class="contact-title">TEL：</p>
                    <div style="display:flex">
                        <p class="contact-desc">03-5822-3021</p>
                         <!-- 旧電話番号03-6879-9908-->
                        <p class="contact-sub-desc">9:30-18:30（土・日・祝祭日除く）</p>
                    </div>

                </div>
                <div style="margin-top:calc(100vw/1920*50)">
                    <p class="contact-title">Email：</p>
                    <div style="display:flex">
                        <p class="contact-desc">info@nsservice.co.jp</p>
                        <p class="contact-sub-desc">24時間×365日</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script >
/* eslint-disable */
import NavBar from '@/components/NavBar'

export default {
    components: {
        NavBar
    },
    // mounted() {
    //     let map = new google.maps.Map(document.getElementById('map'), {
    //         center: center,
    //         zoom: 20
    //     });
    //     marker = new google.maps.Marker({ // マーカーの追加
    //         position: center, // マーカーを立てる位置を指定
    //         map: map // マーカーを立てる地図を指定
    //     });
    // },

    data() {
        return {
            src: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJCXcPhaiOGGARd03lhTJQnFw&key=AIzaSyCS3J6ztp-EBM9J2N5UZ9ZHMtH89VfYJ6U'
        }
    }


}
</script>

<style>
@media screen and (max-width:1920px) and (min-width:768px) {
    .contact-body-container {
        display: flex;
        padding: calc(100vw/1920*50) calc(100vw/1920*360);
    }

    .map-container {
        width: calc(100vw/1920*640);
        height: calc(100vw/1920*440);
        ;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    }

    .contact-title {
        font-size: calc(100vw/1920*24);
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 700;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        line-height: calc(100vw/1920*29);
    }

    .contact-desc {
        width: calc(100vw/1920*200);
        font-size: calc(100vw/1920*18);
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        line-height: calc(100vw/1920*25)
    }

    .contact-sub-desc {
        font-size: calc(100vw/1920*18);
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: calc(100vw/1920*25);
    }

    .contact-banner {
        background-image: url('../assets/images/contact/banner9.png');
    }

    .contact-banner {
        margin-top: 20px;
        height: calc(100vw/1920*400);
        background-size: contain;
        background-repeat: no-repeat;
    }

    .contact-banner div {
        width: calc(100vw/1920*554);
        text-align: left;
        position: absolute;
        top: calc(100vw/1920*192);
        left: calc(100vw/1920*360);
    }

    .contact-banner-title {
        font-size: calc(100vw/1920*30);
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        line-height: 60px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }

    .contact-banner-desc {
        font-size: calc(100vw/1920*16);
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 32px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width:768px) {
    .map-container {
        margin: 0 auto;
        width: 87vw;
        height: 220px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    }

    .contact-title {
        font-size: 14px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 700;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
    }

    .contact-desc {
        width: 35vw;
        font-size: 12px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        line-height: 15px;
    }

    .contact-sub-desc {
        font-size: 12px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 15px;
    }

    .contact-banner {
        height: 140px;
        background-image: url('../assets/images/contact/banner9_mb.png');
        margin-top: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    /* .contact-banner div{
    width: calc(100vw/1920*554);
    text-align: left;
    position: absolute;
    top: calc(100vw/1920*192);
    left:calc(100vw/1920*360);
} */

    .contact-banner div {
        /* width: 100%; */
        text-align: left;
        position: absolute;
        top: 40px;
        left: 60px;
    }

    /* .contact-banner-title{
    font-size:16px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:60px;
    position: relative;
    top: 60px;
    text-shadow:0px 2px 2px rgba(0,0,0,0.15);
  } */

    .contact-banner-title {
        position: relative;
        top: 60px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        line-height: 30px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }


    .contact-banner-desc {
        display: none;
    }

    .contact-body-container {
        padding: 20px;
        /* display: flex;
    align-items: center; */
    }

}

@media screen and (min-width:1920px) {
    .contact-body-container {
        display: flex;
        padding: 50px 360px;
    }

    .map-container {
        width: 640px;
        height: 440px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    }

    .contact-title {
        font-size: 24px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 700;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        line-height: 29px;
    }

    .contact-desc {
        width: 200px;
        font-size: 18px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
    }

    .contact-sub-desc {
        font-size: 18px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 25px;
    }

    .contact-banner {
        background-image: url('../assets/images/contact/banner9.png');
    }

    .contact-banner {
        margin-top: 20px;
        height: 400;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .contact-banner div {
        width: 554px;
        text-align: left;
        position: absolute;
        top: 192px;
        left: 360px;
    }

    .contact-banner-title {
        font-size: 30px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        line-height: 60px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }

    .contact-banner-desc {
        font-size: 16px;
        font-family: Noto Serif JP,YuGothic,serif;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 32px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    }
}
</style>